<template>
  <v-container
    id="panels"
    fluid
    tag="section"
  >
    <v-row>
      <v-col
        class="mx-auto"
        cols="12"
        md="12"
      >

        <base-material-card
          icon="mdi-clipboard-text"
          title="Menu Buat Surat"
          class="px-5 py-3"
        >
          <v-row>
            <v-col class="text-left">
              <v-btn small color="warning" @click="goBack()">
                <v-icon>mdi-arrow-left</v-icon>Kembali
              </v-btn>
            </v-col>
            <v-col class="text-right">
              &nbsp;
            </v-col>
          </v-row>
          
          <v-row>
            <v-col
            cols="12"
              md="12"
            >

              <v-autocomplete
                v-model="JenisSurat"
                :items="dataListLetterType"
                label="Pilih Jenis Surat"
                item-text="text"
                item-value="value"
                :rules="[rules.required]"
              ></v-autocomplete>
        
            </v-col>

             <v-col class="text-right">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn color="blue" v-on="on" @click="nextProcess()">
                      <v-icon>mdi-arrow-right</v-icon>Next
                  </v-btn>
                </template>
                <span>Klik disini untuk melanjutkan proses Buat Surat.</span>
              </v-tooltip>
              
            </v-col>
          </v-row>

        </base-material-card>
      </v-col>
    </v-row>

    <v-snackbar
      v-model="toast.show"
      :timeout="toast.timeout"
    >
      {{ toast.text }}
      <v-btn
        :color="toast.color"
        text
        @click="toast.show = false"
      >
        Close
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="isLoading" fullscreen>
      <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
          <v-layout justify-center align-center>
            <v-card
              width="70vW"
              class="pa-md-2 mx-lg-auto"
              outlined
            >
              <v-list-item three-line centered>
                <v-list-item-content>
                  <div class="overline mb-4">Processing</div>
                  <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                  <v-list-item-subtitle>
                    <v-progress-linear
                      indeterminate
                      color="yellow darken-2"
                    ></v-progress-linear>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>

          </v-layout>
      </v-container>
      </v-dialog>


  </v-container>
</template>

<script>
  import moment from 'moment'
  import secureStorage from '@/utils/secureStorage'
  import { _GLOBAL } from '@/api/server'
  import { getListLetterTypesActive } from '@/api/lettertypes'
  
  export default {
    name: 'MenuBuatSurat',
    data: () => ({
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      rules: {
        required: v => !!v || 'Required.'
      },
      isLoading: false,
      isAdmin: false,
      currrole: null,
      caseuid: 0,
      JenisSurat: 0,
      dataListLetterType: [],
      arrLetterTypes:[]
    }),
    filters: {
      formatDate: function (value) {
        if (!value) return ''
        var sdate = moment(value).format('DD MMM YYYY HH:mm')
        return sdate
      }
    },
    computed: {
    },
    created () {
      const vm = this
      vm.caseuid = vm.$route.params && vm.$route.params.cuid
    },
    destroyed () {
    },
    mounted () {
      const vm = this
      vm.dataUserAll = secureStorage.getItem('userall')
      vm.currrole = secureStorage.getItem('currRole')
      // console.log(currrole)
      if(vm.currrole){
        if(vm.currrole.rolename == 'admin'){
          vm.isAdmin = true
        }
      }
      vm.getLetterTypes()
    },
    methods: {
      goBack () {
        this.$router.go(-1)
      },
      goFileLink (uri) {
        window.open(uri, '_blank');
      },
      getLetterTypes(){
        const vm = this
        vm.isLoading = true
        getListLetterTypesActive().then(response => {
          var data = response.data
          var selectData = []
          data.forEach(element => {
            var tmp = {text: element.type_name, value: element.id}
            selectData.push(tmp)
          });
          vm.arrLetterTypes = data
          vm.dataListLetterType = selectData
          vm.isLoading = false
        }).catch(err => {
          // console.log(err)
          vm.isLoading = false
        })
      },
      nextProcess () {
        const vm = this
        
        var arrSelected = vm.arrLetterTypes.find(x => x.id === vm.JenisSurat)
        // console.log(arrSelected)
        if(arrSelected){
          if(arrSelected.modelsurat){
            if(arrSelected.modelsurat.id == 1){
              vm.showAddFormType1()
            }else if(arrSelected.modelsurat.id == 2){
              vm.showAddFormType2()
            }else if(arrSelected.modelsurat.id == 3){
              vm.showAddFormType3()
            }else if(arrSelected.modelsurat.id == 4){
              vm.showAddFormType4()
            }else if(arrSelected.modelsurat.id == 5){
              vm.showAddFormType5()
            }else{
              vm.toast = {
                show: true, color:'red', text: 'Model Surat OUT OF RANGE pada Jenis Surat, silakan kontak IT Admin', timeout: 2000
              }
            }
          }else{
            vm.toast = {
              show: true, color:'red', text: 'Model Surat NULL pada Jenis Surat, silakan kontak IT Admin', timeout: 2000
            }
          }
        }else{
          vm.toast = {
            show: true, color:'red', text: 'Pilih Jenis Surat terlebih dahulu', timeout: 2000
          }
        }
      },
      showAddFormType1 () {
        const vm = this
        vm.$router.push('/case/crlettera/' + vm.caseuid + '/' + vm.JenisSurat + '/null')
      },
      showAddFormType2 () {
        const vm = this
        vm.$router.push('/case/crletterb/' + vm.caseuid+ '/' + vm.JenisSurat+ '/null')
      },
      showAddFormType3 () {
        const vm = this
        vm.$router.push('/case/crletterc/' + vm.caseuid+ '/' + vm.JenisSurat+ '/null')
      },
      showAddFormType4 () {
        const vm = this
        vm.$router.push('/case/crletterd/' + vm.caseuid+ '/' + vm.JenisSurat+ '/null')
      },
      showAddFormType5 () {
        const vm = this
        vm.$router.push('/case/crlettere/' + vm.caseuid+ '/' + vm.JenisSurat+ '/null')
      },
    }
  }
</script>
